import './App.css';
import axios from "axios";
import Userstats from './components/userstats';
import { useEffect, useState } from 'react';

const API_URL = "https://api.arteco.cloud/user_stats.json?last_minutes=600";
const API_AUTH_URL = "https://api.arteco.cloud/api/login.json";

function getAPIData() {
  console.log("getAPI")
  let data = axios.get(API_URL, {
    headers: { 'Authorization': `Bearer ${localStorage.getItem("jwt_token")}` },
    transformResponse: [function (data) {
      console.log('Transforming data...')
      
      const json = JSON.parse(data)
      const agents = json['agents'];
      const users = json['users'];
      
      data = {
        agents,
        users
      }
      console.log(json)

      console.log('Transforming data finished')
      return data;
    }]
  }).then((response) => response.data)
  .catch(error => { 
    console.log(error)
  });
  console.log("getAPI finished")
  return data;
}

function App() {
  const [userstats, setUserstats] = useState([]);
  const [formValues, setFormValues] = useState({});

  const getAPIAuth = (l, p) => {
    console.log("getAPIAuth")
    let data = axios.post(API_AUTH_URL, {
      api_account: {
        login: l,
        password: p
      }
    })
    .then((response) => { localStorage.setItem("jwt_token", response.data.jwt) })
    .catch(error => { 
      console.log(error)
      localStorage.setItem("jwt_token", "")
    });
    console.log(localStorage.getItem("jwt_token"))
    console.log("getAPIAuth finished")
    return data;
  }

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    getAPIAuth(formValues.login,formValues.password);
    if (localStorage.getItem("jwt_token"))
      getAPIData().then((items) => {
        setUserstats(items);
      });
  };

  useEffect(() => {
    console.log("useEffect started")
    let mounted = true;
    
    console.log("useEffect finished")
    return () => (mounted = false);
  }, []);

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <input
            type="text"
            id="login"
            placeholder="Login" 
            autoComplete="true"
            value={formValues.login || ""}
            onChange={handleChange}
          />
          <input
            type="password"
            id="password"
            placeholder="Password" 
            autoComplete="true"
            value={formValues.password || ""}
            onChange={handleChange}
          />
          <button type="submit" className="submit-btn">
            Load
          </button>
        </div>
      </form>
      <div  className='container'>
        <h1>User Statistics</h1>
      </div>
      <Userstats userstats={userstats} />
    </div>
  );
}

export default App;
