import React from 'react'

function Userstats(props) {
  let agents = props.userstats.agents;
  let users = props.userstats.users;

  console.log(props.userstats.users)

  if (agents) {
    return <div className='userstats-container'>
      <h2 className='list-head'>User-Agents</h2>
      
      {agents.map((agent, index) => {
        return <div key={index} className='user-agent'>
          <span className='agent-count'>{agent.count} </span>
          <span className='agent-user-agent'>{agent.user_agent}</span>
        </div>;
      })}
      <h2 className='list-head'>Users</h2>
      {props.userstats.users.map((user, index) => {
        return <div key={index} className='user'>
          <span className='user-idle-since'>{user.idle_since}</span>
          <span className='user-name'>{user.name} </span>
          <span className='user-customer'>({user.customer}) </span>
          <span className='user-user-agent'>{user.user_agent} </span>
        </div>;
      })}
    </div>

  }
}

export default Userstats